/**
 * NavBar
 * 
 * @Author: Focci
 * @Date: 2023-06-05 09:42:20
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-05 09:42:20
 */

'use client'

// import PropTypes from 'prop-types'
import { LinkTo } from '@comp/LinkTo'
import clsx from 'clsx'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import menuIcon from '@img/hougarden_header_nav.png'
import { chineseCheck } from '@i18n/config'
import { isAU } from '@lib/utils'
import { useLocale, useTranslations } from 'next-intl'
import { urls } from '@lib/parse'
import * as routeCommon from '@lib/route/common'
import * as routeHouse from '@lib/route/house'
import * as routeAgent from '@lib/route/agent'
import * as routeProfile from '@lib/route/profile'
import * as routePS from '@lib/route/productspec'
import { useRef, useMemo } from 'react'
import { houseSearchType } from '@lib/config'
import styl from './navbar.module.css'

export function getMenuOptions(tm, isChinese, locale) {
  // 新闻子菜单
  const newsChildren = [
    { 
      label: tm('news'), 
      description: tm('newIntro'), 
      bgpos: '0 -280px', 
      url: routeCommon.newsHomepage('all', 1, locale) 
    },
    { 
      label: tm('topic'), 
      description: tm('topicIntro'), 
      bgpos: '-70px -280px', 
      url: routeCommon.topicHomepage('all', locale)
    },
    { 
      label: tm('fmBroadcast'), 
      description: tm('fmIntro'), 
      bgpos: '-140px -280px', 
      url: routeCommon.fmHomepage('', locale)
    },
    { 
      label: tm('knownledge'), 
      description: tm('expertInterpretation'), 
      bgpos: '0 -350px', 
      url: routeCommon.knowledgeHomepage('all', 1, locale)
    },
    { 
      label: tm('live'), 
      description: tm('liveOnline'), 
      bgpos: '-70px -350px', 
      url: routeCommon.liveHomepage('all', 1, locale)
    }
  ]

  // 买房子菜单
  const listingChildren = [
    { 
      label: tm('houseForSale'), 
      description: tm('houseForSaleIntro'),
      bgpos: '0 0', 
      url: urls.listingDefault(houseSearchType.residential, locale)
    },
    { 
      label: tm('propertyEstimate'), 
      description: tm('propertyEstimateIntro'),
      bgpos: '-70px 0', 
      url: urls.listingDefault(houseSearchType.estimate, locale)
    },
    { 
      label: tm('development'), 
      description: tm('developmentIntro'),
      bgpos: '-140px 0', 
      url: urls.listingDefault(houseSearchType.development, locale)
    },
    { 
      label: tm('sold'), 
      description: tm('soldIntro'),
      bgpos: '0 -70px', 
      url: urls.listingDefault(houseSearchType.sold, locale)
    },
    { 
      label: tm('newHouse'), 
      description: tm('newHouseIntro'),
      bgpos: '-70px -70px', 
      url: urls.listingDefault(houseSearchType.newHomes, locale)
    },
    { 
      label: tm('areaAnalysis'), 
      description: tm('areaAnalysisIntro'),
      bgpos: '-140px -70px', 
      url: routeHouse.suburbs(undefined, locale)
    }
  ]

  // 租房子菜单
  const rentalChildren = [
    { 
      label: tm('rental'), 
      description: tm('rentalIntro'),
      bgpos: '0 -140px', 
      url: urls.listingDefault(houseSearchType.rent, locale)
    },
    { 
      label: tm('rentalPublish'), 
      description: tm('rentalPublishIntro'),
      bgpos: '-140px -140px', 
      url: routeProfile.publish(locale)
    },
  ]

  const tmp = []

  if(isChinese) {
    tmp.push({ 
      label: tm('information'), 
      url: routeCommon.newsHomepage('all', 1, locale),
      children: isAU ? null : newsChildren
    })
  }

  tmp.push(
    { 
      label: tm('houseForSale'), 
      url: urls.listingDefault(houseSearchType.residential, locale), 
      children: isAU ? null : listingChildren
    },
    { 
      label: tm('rental'), 
      url: urls.listingDefault(houseSearchType.rent, locale), 
      children: isAU ? null : rentalChildren
    },
  )

  if(isAU) {
    tmp.push(
      { 
        label: tm('sold'), 
        url: urls.listingDefault(houseSearchType.sold, locale), 
      },
      { 
        label: tm('newHouse'), 
        url: urls.listingDefault(houseSearchType.newHomes, locale)
      },
      { 
        label: tm('acutionResults'),
        url: routeHouse.auctionHome(null, locale),
      }
    )
  }

  if(!isAU) {
    tmp.push(
      { 
        label: tm('acutionResults'),
        url: routeHouse.auctionHome(null, locale), 
      },
      { 
        label: tm('agent'),
        url: routeAgent.findAnAgent(locale), 
      },
      { 
        label: tm('commercial'), 
        url: urls.listingDefault(houseSearchType.commercialForSale, locale), 
      },
      { 
        label: tm('rural'), 
        url: urls.listingDefault(houseSearchType.rural, locale), 
      },
      { 
        label: tm('productspec'), 
        url: routePS.home(locale), 
      },
    )
  }

  return tmp
}

/**
 * 设置二级菜单显示位置
 */
export default function NavBar() {
  const refEl = useRef()
  const tm = useTranslations('menu')

  const locale = useLocale()
  const isChinese = chineseCheck(locale)
  const menus = useMemo(() => getMenuOptions(tm, isChinese, locale), [tm, isChinese, locale])
  
  return (
    <ul className={styl.navbar} ref={refEl}>
      {menus.map((menu) => (
        <li 
          key={menu.label} 
          className={clsx([menu.children ? styl['has-child'] : '', 'group/nav-bar'])}
        >
          <LinkTo href={menu.url} prefetch={false} className="relative">
            <span className="font-bold whitespace-nowrap">{menu.label}</span>
            { menu.children && (
              <span className="flex items-center">
                <KeyboardArrowDown sx={{ fontSize: '.9rem' }} />
              </span>
            )}
            <div 
              className="
                absolute bottom-0 left-1/2 -translate-x-1/2 h-[.1rem] bg-white
                transition-all w-0 group-hover/nav-bar:w-full
              "
            />
          </LinkTo>
          { menu.children && (
            <ul 
              className={clsx([
                styl.submenu,
                menu.children.length > 2 ? 'grid-cols-3' : 'grid-cols-2 !left-40',
              ])}
            >
              {menu.children.map((child) => (
                <li key={child.label}>
                  <LinkTo 
                    href={child.url}
                    prefetch={false}
                    className={clsx([styl.item, 'transition-colors hover:bg-ce6'])}
                  >
                    <i 
                      style={{
                        backgroundImage: `url(${menuIcon.src})`,
                        backgroundPosition: child.bgpos 
                      }} 
                    />
                    <div className="flex flex-col ml-3 flex-auto overflow-hidden">
                      <b>{child.label}</b>
                      <span className="truncate block">{child.description}</span>
                    </div>
                  </LinkTo>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  )
}

// NavBar.propTypes = {
//   className: PropTypes.string,
// }
